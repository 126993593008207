<template>
  <div class="bg-white p-10">
    <a-breadcrumb separator=">" class="mb">
      <a-breadcrumb-item>资金概览</a-breadcrumb-item>
      <a-breadcrumb-item href="">
        余额提现
      </a-breadcrumb-item>
    </a-breadcrumb>

    <a-row :gutter="10" class="mb">
      <a-col :span="8" :md="8" :sm="24" v-for="(item,index) in withdrawDepositData" :key="index">
        <a-card class="bg">
          <div class="surver-dispaly">
            <div class="surver-acct">
              <img :src="item.icon" alt="">
              <div class="cards-item-left">
                <p class="surver-price">￥{{ item.price }}</p>
                <p class="surver-color">{{ item.title }}</p>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-card>
      <div class="common-tip mb-20">收款账户</div>
      <a-form-model
        :model="form"
        ref="WithdrawDepositForm"
        :rules="withdrawDepositRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="公司名称">
          <a-input class="tips-input-width" v-model="userData.businessName"  disabled />
        </a-form-model-item>
        <a-form-model-item label="开户行" prop="withdrawalsBank">
          <a-input class="tips-input-width" :maxLength="256" v-model="form.withdrawalsBank" />
        </a-form-model-item>
        <a-form-model-item label="银行卡账号" prop="withdrawalsNumber">
          <a-input class="tips-input-width" :maxLength="32" v-model="form.withdrawalsNumber" />
        </a-form-model-item>
        <div class="common-tip mb-20">提现金额</div>
        <a-form-model-item label="提现金额" prop="withdrawalsAmount">
          <a-input class="tips-input-width" addon-before="￥" v-model="form.withdrawalsAmount" @input="numPrice($event)" @change="change" />
        </a-form-model-item>
        <a-form-model-item label="实际到账" prop="amount">
          <a-input class="tips-input-width" addon-before="￥" v-model="form.amount" disabled />
          <p style="color: #999;font-size: 12px;line-height: 12px;">服务费0%</p>
        </a-form-model-item>
        <a-form-model-item label="绑定手机号" prop="phone">
          <a-input class="tips-input-width" v-model="userData.phone" disabled />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code">
          <div style="display: flex;align-items: center;">
            <div>
              <a-input style="width: 390px;" :maxLength="6" v-model="form.code" />
            </div>
            <a-button
              type="primary"
              ghost
              class="formImg"
              style="margin-left: 10px;border: 1px solid #2ba7ff;color: #2ba7ff;"
              :loading="loading"
              @click="getCode"
              :disabled="codeText!==&quot;获取验证码&quot;"
            >
              {{ codeText }}
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 6, offset: 2 }">
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submit"
            style="background-color: #1890ff;border-color: #1890ff;">
            提交申请
          </a-button>
        </a-form-model-item>
      </a-form-model>

      <div class="common-tip common-tip2 mb-20">温馨提示</div>
      <div>
        <p class="warm-txt">
          1、申请的提现金额不得小于200元。
        </p>
        <p class="warm-txt">
          2、申请的提现仅能提现至商户的实名认证信息同名账户的银行卡。
        </p>
        <p class="warm-txt">
          3、申请的提现需3个工作日审核，审核通过后将线下发起银行打款，预计3个工作日到账，节假日顺延，请以实际到账时间为准。
        </p>
        <p class="warm-txt">
          4、若提现审核期间存在违规行为，则到账时间可能有延迟，请以实际到账时间为准。
        </p>
      </div>
    </a-card>

  </div>
</template>

<script>
import {
  withdrawDepositData,
  withdrawDepositRules,
  wrapperCol,
  labelCol
} from './iconImg'
import { withdrawals, sendWithdrawals ,getMoney} from '@/api/finance/stream'
import { mapGetters } from 'vuex'
import { getUserInfoRz } from '@/api/Login'
import { limitNumTool } from '@/utils/utils'

export default {
  name: 'WithdrawDeposit',
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      withdrawDepositData,
      withdrawDepositRules,
      form: {
        'amount': null,
        'code': null,
        'createOrUpdate': 0,
        'createTime': null,
        'endTime': null,
        'numberNo': null,
        'phone': null,
        'rates': 0,
        'remark': null,
        'serviceCharge': 0,
        'startTime': null,
        'updateTime': null,
        'userId': 0,
        'userName': null,
        'uuid': null,
        'withdrawalsAmount': null,
        'withdrawalsBank':null,
        'withdrawalsNumber': null,
        'withdrawalsState': 0,
        'withdrawalsTime': null,
        'withdrawalsType': 3
      },
      labelCol,
      wrapperCol,
      loading: false,
      submitLoading: false,
      codeNumber: 60,
      codeText: '获取验证码',
      timer: null,
      userData:{},
      Money:''
    }
  },
  created () {
    console.log('userInfo', this.userInfo)
    // this.form.phone = this.userInfo.user.phonenumber
    getUserInfoRz().then(res => {
      console.log('userData',res)
        this.userData = res.data
      })
    getMoney().then(res => {
      this.withdrawDepositData[0].price = res.data
    })
  },
  methods: {
    numPrice(value) {
      this.form.withdrawalsAmount = limitNumTool(value.target.value, 24)
    },
    getCode () {
      console.log('getCode')
      if (!this.loading) {
        this.loading = true
        sendWithdrawals().then(res => {
          console.log('发送成功', res)
          this.loading = false
          this.form.uuid = res.data
          this.$message.success('验证码发送成功', 3)
          this.timer = setInterval(() => {
            if (this.codeNumber <= 1) {
              clearInterval(this.timer)
              this.codeText = '获取验证码'
              this.codeNumber = 60
              return
            }
            this.codeNumber--
            this.codeText = this.codeNumber + '秒后重发'
            console.log('this.codeNumber', this.codeNumber, this.codeText)
          }, 1000)
        })
      }
    },
    change (v) {
      console.log(v)
      this.form.amount = limitNumTool(v.target.value, 24)
    },
    submit () {
      this.$refs.WithdrawDepositForm.validate(valid => {
        if (valid) {
          console.log('submit!', this.form)
          if (!this.submitLoading) {
            this.submitLoading = true
            withdrawals(this.form).then(res => {
              if(res.code != -1){
                this.submitLoading = false
                this.$message.success('提现提交成功，等待审核！', 3)
                clearInterval(this.timer)
                // 跳到提现记录页面
                this.$router.push({ path: '/user/withdrawals'})
              } else {
                this.$message.error(res.msg)
                this.submitLoading = false
              }
            }).catch(err => {
              console.log('提现err', err)
              this.submitLoading = false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.ant-form-item ::v-deep .ant-col-2 {
  width: 9.33333333%;
}
.tips-input-width{
  width: 500px;
}
.surver-color, .surver-price {
  margin-bottom: 0;
}

.mb {
  margin-bottom: 15px;
}

.bg {
  background: url(https://console-mch.duobaoyu.com.cn/img/withdraw-bg.a845efcb.png) 100% no-repeat;
  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 2%);
  background-size: 100% 100%;
}
</style>
