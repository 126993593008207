
import request from '@/utils/axios'

export const getStreamList = (params) => {
  return request({
    url: '/turnover/amount/list',
    method: 'get',
    params
  })
}

// 资金概览-资金管理
export const getWalletInfo = (params) => {
  return request({
    url: '/system/wallet/info',
    method: 'get',
    params
  })
}

// 资金概览-交易数据
export const getTransactionData = (params) => {
  return request({
    url: '/system/wallet/transaction/data',
    method: 'get',
    params
  })
}

// 资金概览-财务汇总
export const getFinancial = (params) => {
  return request({
    url: '/system/wallet/financial/summary',
    method: 'get',
    params
  })
}

// 资金概览-充值信息
export const information = (data) => {
  return request({
    url: '/supplyportal/supplyWalletRecharge/recipient/information',
    method: 'get',
    data
  })
}

// 资金概览-充值
export const recharge = (data) => {
  return request({
    url: '/supplyportal/supplyWalletRecharge/recharge',
    method: 'post',
    data
  })
}

// 资金概览-提现发送验证码
export const sendWithdrawals = (data) => {
  return request({
    url: '/supplyportal/supplyWalletWithdrawals/send/withdrawals',
    method: 'get',
    data
  })
}

// 资金概览-提现
export const withdrawals = (data) => {
  return request({
    url: '/supplyportal/supplyWalletWithdrawals/withdrawals',
    method: 'post',
    data
  })
}

// 财务管理-充值记录
export const walletTable = (params) => {
  return request({
    url: '/system/recharge/list',
    method: 'get',
    params
  })
}

// 财务管理-提现记录
export const withdrawalsTable = (params) => {
  return request({
    url: '/system/withdrawals/list',
    method: 'get',
    params
  })
}

// 财务管理 结算列表
export const clearingTable = (params) => {
  return request({
    url: '/system/clearing/list',
    method: 'get',
    params
  })
}

// 财务管理 结算数据
export const clearingData = (params) => {
  return request({
    url: '/system/clearing/count',
    method: 'get',
    params
  })
}

// 查钱包余额

export const getMoney = (params) => {
  return request({
    url: '/supplyportal/supplyWallet/getMoney',
    method: 'get',
    params
  })
}

// 资金概览列——财务汇总列表
export const getAmountOverviewList = (params) => {
  return request({
    url: '/supplyportal/turnoverAmount/queryAmountOverview',
    method: 'get',
    params
  })
}
// 资金概览列——交易数据
export const getAmountInfo = (params) => {
  return request({
    url: '/supplyportal/turnoverAmount/queryAmountInfo',
    method: 'get',
    params
  })
}


export const getPreWithdrawals = () => {
  return request({
    url: '/supplyportal/supplyWalletRecharge/preWithdrawals',
    method: 'get'
  })
}


export const getWithdrawalsPre = () => {
  return request({
    url: '/supplyportal/supplyWalletWithdrawals/preWithdrawals',
    method: 'get'
  })
}

// 查询待结算金额
export const getWaitAmount = () => {
  return request({
    url: '/supplyportal/supplyWalletWithdrawals/getWaitAmount',
    method: 'get'
  })
}


// 充值

export function Payonli(data){
  return request({
    url:'/supplyportal/supplyWalletRecharge/onLineRecharge',
    method:'post',
    data:data
  })
}
